





















































































import {Component, Vue} from "vue-property-decorator";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import RouteNames from "@/router/RouteNames";
import DocumentService from "@/services/DocumentService";
import RegexUtils from "@/utils/RegexUtils";
import SignableDocumentFilter from "@/dto/archive/SignableDocumentFilter";
import PaginationComponent from "@/components/util/PaginationComponent.vue";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {PaginationComponent}
})
export default class SignableDocumentsList extends Vue {

    private successful = true;
    private message = "";

    private signableDocuments: Array<SignableDocumentDTO> = [];

    @AppModule.State
    private isMobile!: boolean;

    @AppModule.State
    private loading!: boolean;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    @Auth.Getter
    private hasAdministrativePrivileges!: boolean;

    private filter = new SignableDocumentFilter();

    private totalPages = 1;

    mounted() {
        this.filter.customer = this.$route.query.customer as string;
        this.filter.documentSubType = this.$route.query.documentSubType as string;
        if (!this.hasAdministrativePrivileges) {
            this.$router.push(RouteNames.HOME);
        } else {
            this.loadDocuments();
        }
    }
    private documentTypes = [
      'FORM_CT_1065_1120_CT', 'FORM_8879_WH', 'FORM_8453_C_CALIFORNIA_2023', 'KY_F8879_2023','F_1120A', 'FORM_IL_1120_ST', 'FORM_CBT_100_NJ','FORM_8879_PE_2023', 'TR_579_CT_2023', 'NYC_579_COR_2023', 'FORM_8879_C_2023','FORM_ACH_DEBIT_INDIVIDUAL',
      'FORM_ACH_DEBIT_COMPANY', 'FORM_8879_C_2021', 'FORM_8879_S', 'FORM_8879_2022', 'FORM_4506_T', 'FORM_2848', 'FORM_IT_2104', 'FORM_W_8BEN', 'FORM_W_4', 'FORM_W_9',
      'FORM_2553', 'FORM_CT_6', 'FORM_8655', 'FORM_TR_2000', 'QUESTIONNAIRE_CONFIRMATION', 'TR_579_IT_2022', 'NYC_579_COR_2021', 'NYC_579_COR_2022', 'TR_579_CT_2021',
      'FORM_8453_GEORGIA_2022', 'FORM_8453_CALIFORNIA_2022', 'FORM_8453_PENNSYLVANIA_2022', 'FORM_M_8453_MASSACHUSETTS_2022', 'FORM_VA_8879_VIRGINIA_2022',
      'FORM_EL101_MARYLAND_2022', 'FORM_IL_8453_ILLINOIS_2022', 'FORM_DR_8454_COLORADO_2022', 'FORM_8453_C_CALIFORNIA_2022', 'FORM_SC_8453_2022', 'FORM_VA_8879С_VIRGINIA_2022',
      'FORM_1100_DELAWARE_2022', 'TR_579_CT_2022', 'FORM_PA_8879P_2022', "FORM_8879_PE_2022", 'FORM_PA_8879_C_PENNSYLVANIA_2022', 'TR_579_PT_2022', "NYC_579_UBTP_2022",
      'AZ_8879_2022', 'FORM_IT_204_LL_2023', 'FORM_SC_8453_C_2021', 'FORM_1100_DELAWARE_2023', "F_3115_2022"
    ];

    loadDocuments() {
        this.message = "";
        this.startLoading();
        DocumentService.loadSignableDocuments(this.filter).then(
            response => {
                this.signableDocuments = response.data.data;
                this.totalPages = response.data.countOfPages;
                this.successful = true;
                this.stopLoading();
            },
            error => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
                this.successful = false;
                this.stopLoading();
            }
        );
    }

	private download(doc: SignableDocumentDTO) {
		if (doc.signedDocument) {
			Vue.prototype.$docUtils.download(doc.signedDocument.id)
		} else {
			Vue.prototype.$docUtils.download(doc.primaryDocument!.id)
		}
	}

	refreshDocument(document: SignableDocumentDTO) {
		this.startLoading();
		this.message = "";
		DocumentService.refreshSignableDocument(document).then(
			response => {
				document.id = response.data.id;
				document.primaryDocument = response.data.primaryDocument;
				document.signedDocument = response.data.signedDocument;
				document.documentType = response.data.documentType;
				document.externalId = response.data.externalId;
				document.personId = response.data.personId;
				document.companyId = response.data.companyId;
				document.status = response.data.status;
				this.successful = true;
				this.stopLoading();
			},
			error => {
				this.stopLoading();
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				this.successful = false;
			}
		);
	}

    applyRouteParams() {
      // непонятный редирект на главную
        let dict: { [key: string] : string } = {};

        if (this.filter.customer) {
            dict.customer = this.filter.customer;
        }

        if (this.filter.documentSubType) {
            dict.documentSubType = this.filter.documentSubType;
        }

        this.$router.replace({
            query: dict
        })
    }


    changePage(page: number) {
        this.filter.page = page;
        this.loadDocuments();
    }

    formatDate(d: Date) {
        if (!d) {
            return '';
        }
        const date = new Date(d);
        return date.toLocaleDateString();
    }

    handleCustomer(event: KeyboardEvent) {
        if (event.key != '\'' && event.key != "-" && event.key != " " && !RegexUtils.testLatin(event.key)) {
            event.preventDefault();
        }
    }

    clearFilter() {
        this.filter = new SignableDocumentFilter();
        this.loadDocuments();
    }


}
